import Image from '../Image';
import LinkSafe from '../LinkSafe';
import { SwiperCustom } from '../Nodiscrimination';
import { Tit } from '../Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

interface PeopleProps {
  itemData: PeopleItem[];
  type?: string;
  isSwiper?: boolean;
  hash?: string;
}

export interface PeopleItem {
  id: string | number;
  name?: string;
  dec?: string;
  link?: string;
  img: ImgHTMLAttributes<any>['src'];
}

const Item = styled.div`
  .img-wrap,
  .dec-wrap {
    display: block;
    width: 100%;
    text-align: center;
    position: relative;
  }

  .inner-dec {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(6, 18, 29, 0.55) 47%
    );

    ${Tit} {
      display: inline-block;
      line-height: 40px;
      background: #1cabe2;
      color: #fff;
      padding: 0 24px;
      font-weight: 400 !important;
    }
    .dec {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #fff;
      line-height: 2;
      padding: 0 24px;
      margin: 8px 0 16px;
      font-weight: 700;
    }

    ${breakpoint(`tablet`)} {
      ${Tit} {
        padding: 0 16px;
        line-height: 36px;
      }
      .dec {
        padding: 0 16px;
      }
    }
  }

  .dec-wrap {
    margin-top: 32px;

    .dec {
      line-height: 1.7;
      width: 100%;
      text-align: left;
      margin-top: 8px;
    }
  }
`;

const NoLinke = styled(Item)`
  cursor: default;
`;

const ItemList = styled.ul`
  display: flex;
  margin: 0 -16px;
  flex-wrap: wrap;

  & > li {
    padding: 0 16px;
  }
`;
const ListContainer = styled.div<PeopleProps>`
  .link-item {
    display: block;
    cursor: pointer;
  }
  ._swiper {
    padding: 0;

    .swiper-slide {
      margin-right: 32px;
      width: 350px;
      padding: 0 32px;
    }

    &.card {
      .swiper-slide {
        margin-right: 32px;
        width: 320px;
        padding: 0;

        .dec-wrap {
          margin-top: 22px;
          font-weight: 700;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      .swiper-scrollbar {
        left: 0;
        right: 0;
      }
      .swiper-slide {
        width: 256px;
        padding: 0;
      }
      &.card {
        .swiper-slide {
          width: 280px;
        }
      }
    }
  }
`;

const PeopleList = styled<FC<PeopleProps>>(
  ({ itemData, isSwiper, type, hash, ...props }) => (
    <ListContainer>
      {isSwiper ? (
        <SwiperCustom className={type ? `_swiper ${type}` : `_swiper`}>
          <Swiper
            slidesPerView="auto"
            navigation
            scrollbar={{ draggable: false }}
          >
            {itemData.map((row) => (
              <SwiperSlide key={row.id}>
                {row.link ? (
                  <LinkSafe
                    to={row.link}
                    onClick={() => {
                      if (hash) {
                        window.location.hash = hash;
                      }
                    }}
                    className="link-item"
                  >
                    <Item>
                      <div className="img-wrap">
                        <Image pcSrc={row.img} mobileSrc={row.img} />
                      </div>
                      <div className="dec-wrap">
                        {row.name && (
                          <Tit
                            size="s4"
                            dangerouslySetInnerHTML={{ __html: row.name }}
                          />
                        )}
                        {row.dec && (
                          <p
                            className="dec"
                            dangerouslySetInnerHTML={{ __html: row.dec }}
                          />
                        )}
                      </div>
                    </Item>
                  </LinkSafe>
                ) : (
                  <NoLinke>
                    <div className="img-wrap">
                      <Image pcSrc={row.img} mobileSrc={row.img} />
                    </div>
                    <div className="dec-wrap">
                      {row.name && (
                        <Tit
                          size="s4"
                          dangerouslySetInnerHTML={{ __html: row.name }}
                        />
                      )}
                      {row.dec && (
                        <p
                          className="dec"
                          dangerouslySetInnerHTML={{ __html: row.dec }}
                        />
                      )}
                    </div>
                  </NoLinke>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperCustom>
      ) : (
        <ItemList className="item-list">
          {itemData.map((row) => (
            <li key={row.id}>
              <LinkSafe
                to={row.link}
                onClick={() => {
                  if (hash) {
                    window.location.hash = hash;
                  }
                }}
                className="link-item"
              >
                <Item>
                  <div className="img-wrap">
                    <Image pcSrc={row.img} mobileSrc={row.img} />

                    {type === `special` && (
                      <div className="inner-dec">
                        <Tit
                          size="s4"
                          dangerouslySetInnerHTML={{ __html: row.name }}
                        />
                        <p
                          className="dec"
                          dangerouslySetInnerHTML={{ __html: row.dec }}
                        />
                      </div>
                    )}
                  </div>
                  {type !== `special` && (
                    <div className="dec-wrap">
                      {row.name && (
                        <Tit
                          size="s4"
                          dangerouslySetInnerHTML={{ __html: row.name }}
                        />
                      )}
                      {row.dec && (
                        <p
                          className="dec"
                          dangerouslySetInnerHTML={{ __html: row.dec }}
                        />
                      )}
                    </div>
                  )}
                </Item>
              </LinkSafe>
            </li>
          ))}
        </ItemList>
      )}
    </ListContainer>
  ),
)``;
export default PeopleList;
