import { PeopleControllerService, PeopleVo } from '@/__generated__/FrontApi';
import SecBg from '@/assets/img/tmp/people/people_bg.png';
import SecBgM from '@/assets/img/tmp/people/people_bgM@2x.png';
import PeopleList, { PeopleItem } from '@/components/Card/PeopleCard';
import Container from '@/components/Container';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import 'swiper/swiper.min.css';

const Article1 = styled.article`
  position: relative;
  padding-bottom: 120px;

  &::before {
    content: '';
    display: block;
    width: 1920px;
    height: 333px;
    background: url(${SecBg}) center no-repeat;
    background-size: cover;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
  }

  .item-list {
    position: relative;
    z-index: 1;
    margin: 0 -40px;

    & > li {
      padding: 0 40px;
      width: 33.33%;
    }
    .img-wrap {
      border-radius: 0 0 40px 0;
      overflow: hidden;
    }
  }

  ${breakpoint(1160)} {
    padding-bottom: 10.34vw;

    &::before {
      width: 165.52vw;
      height: 28.71vw;
    }

    .item-list {
      margin: 0 -3.45vw;

      & > li {
        padding: 0 3.45vw;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .item-list {
      margin: 0 -12px;

      & > li {
        padding: 0 12px;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    padding-bottom: 124px;

    &::before {
      background: #e2f0f6 url(${SecBgM}) right bottom no-repeat;
      height: 332px;
      background-size: 360px 332px;
      width: auto;
      left: -20px;
      right: -20px;
      transform: translate(0, 0);
    }

    .item-list {
      & > li {
        width: 100%;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
const Article2 = styled.article`
  padding-top: 132px;

  .item-list {
    & > li {
      width: 25%;
      margin-bottom: 72px;
    }
  }

  ${breakpoint(`tablet`)} {
    padding-top: 120px;
    .item-list {
      & > li {
        width: 33.33%;
        margin-bottom: 48px;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .item-list {
      & > li {
        width: 100%;

        .img-wrap {
          max-width: 256px;
          margin: 0 auto;
        }
      }
    }
  }
`;
const Article3 = styled.article`
  padding-top: 152px;

  h3 {
    padding-bottom: 32px;
  }

  .swiper-slide {
    .img-wrap {
      overflow: hidden;
      border-radius: 0 0 40px 0;
    }
  }
`;

const HeaderFlex = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 96px;

  ${breakpoint(`tablet`)} {
    ${Tit} {
      width: calc(100% - 70px);
    }
    .more {
      width: 70px;
    }
  }
`;

const SectionTop = styled(Section)`
  background: #f8f9fa;
  ${SectionHeader} {
    padding-bottom: 72px;
  }
  ${breakpoint(`tablet`)} {
  }
`;
const SectionBecome = styled(Section)`
  .partner-article {
    margin-top: 240px;
    .img-wrap {
      padding: 44px 48px;
      padding-bottom: 16px;
    }
    .dec-wrap {
      border-top: 1px solid #b7b8ba;
      padding-top: 16px;
      line-height: 2;
    }
  }

  ${breakpoint(`tablet`)} {
    .partner-article {
      margin-top: 120px;
    }
  }
  ${breakpoint(`mobile`)} {
    .partner-article {
      ${Tit} {
        max-width: 250px;
      }
    }
  }
`;

const SectionUnicaster = styled(Section)``;

const SectionWork = styled(Section)``;

const People: React.FC<PageProps> = ({ location }) => {
  const baseUri = `/about-us/people`;

  const [goodwill, setGoodwill] = useState<PeopleItem[]>([]);
  const [special, setSpecial] = useState<PeopleItem[]>([]);
  const [global, setGlobal] = useState<PeopleItem[]>([]);
  const [sponsor, setSponsor] = useState<PeopleItem[]>([]);
  const [partner, setPartner] = useState<PeopleItem[]>([]);
  const [unicaster, setUnicaster] = useState<PeopleItem[]>([]);
  const [worker, setWorker] = useState<PeopleItem[]>([]);

  const loadPeople = useCallback(async () => {
    try {
      const {
        data: people,
      } = await PeopleControllerService.getPeopleAllUsingGet();

      people
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((person: PeopleVo) => {
          const tmpData = {
            id: Number(person.peopleIndexNumber),
            name: `${person.name} ${person.role}` || undefined,
            dec: person.subText || undefined,
            link:
              person.detailPageYn === `Y`
                ? `${baseUri}${person.relUrl}`
                : person.relUrl || undefined,
            img: person.attGrpNoTn,
          };

          if (person.categoryCode === `P01`) {
            // 친선대사 그룹1
            setGoodwill((prevGoodwill) => [...prevGoodwill, tmpData]);
          } else if (person.categoryCode === `P02`) {
            // 친선대사 그룹2
            setSpecial((prevSpeical) => [...prevSpeical, tmpData]);
          } else if (person.categoryCode === `P03`) {
            // 글로벌 친선대사
            setGlobal((prevGlobal) => [...prevGlobal, tmpData]);
          } else if (person.categoryCode === `P04`) {
            // 후원자
            tmpData.link = `/involve/donors/interview`;
            setSponsor((prevSponsor) => [...prevSponsor, tmpData]);
          } else if (person.categoryCode === `P05`) {
            // 파트너
            tmpData.name = undefined;
            tmpData.link = `/involve/corporate-and-organization/social-contribution`;
            setPartner((prevPartner) => [...prevPartner, tmpData]);
          } else if (person.categoryCode === `P06`) {
            // 유니케스터
            tmpData.name = `${person.role} ${person.name}` || undefined;
            setUnicaster((prevUnicaster) => [...prevUnicaster, tmpData]);
          } else if (person.categoryCode === `P07`) {
            // 직원
            const newName = `${person.role} <br />${person.name}`;
            tmpData.name = newName;
            if (person.relUrl === null) {
              tmpData.link = `/what-we-do/news`;
            } else {
              tmpData.link = person.relUrl;
            }
            setWorker((prevWorker) => [...prevWorker, tmpData]);
          }
        });
    } catch (e) {
      console.error(e);
    }
  }, [baseUri]);

  useEffect(() => {
    loadPeople();
  }, [loadPeople]);

  return (
    <LayoutWithTitle
      location={location}
      title="유니세프와 사람들"
      description="for every child, people"
    >
      <SectionTop className="by-sub-main-layout" id="goodwill">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                <PageComponent id="title1">
                  함께하는 친선대사
                </PageComponent>
              </Tit>
            </h2>

            <p className="header-dec">
              <PageComponent id="content1">
                유니세프는 명망 있는 문화체육 예술인을 친선대사로
                임명해 지구촌 어린이들이 더 많은 관심과 지원을 받을 수 있도록
                함께하고 있습니다. 한국위원회의 친선대사는 각자의
                분야에서 유니세프를 홍보하고 지원하는 역할을 하며, 유니세프의
                현지시찰, 자선행사 참여 등으로 유니세프 사업을 돕고 있습니다.
              </PageComponent>
            </p>
          </SectionHeader>

          <Article1>
            <PeopleList itemData={goodwill} type="special" hash="goodwill" />
          </Article1>

          <Article2 id="special">
            <PeopleList itemData={special} hash="special" />
          </Article2>

          <Article3 id="global">
            <h3>
              <Tit size="s2">글로벌 친선대사</Tit>
            </h3>
            <PeopleList itemData={global} isSwiper type="card" hash="global" />
          </Article3>
        </Container>
      </SectionTop>

      <SectionBecome className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                유니세프 후원자가 <br />
                된다는 건
              </Tit>
            </h2>
          </SectionHeader>

          <article id="sponsor">
            <HeaderFlex>
              <Tit size="s2">50만 후원자가 유니세프와 함께하고 있습니다</Tit>
              <LinkSafe to="/involve/donors/interview" className="more">
                <span
                  className="txt"
                  css={`
                    font-weight: bold;
                  `}
                >
                  더 보기
                </span>
              </LinkSafe>
            </HeaderFlex>
            <PeopleList itemData={sponsor} isSwiper hash="sponsor" />
          </article>

          <article className="partner-article" id="partner">
            <HeaderFlex>
              <Tit size="s2">
                전 세계적으로 수많은 기업들이 <br className="small-hide" />
                유니세프의 파트너가 되어 <br className="small-show" />
                참여하고 있습니다
              </Tit>
              <LinkSafe
                to="/involve/corporate-and-organization/social-contribution"
                className="more"
              >
                <span
                  className="txt"
                  css={`
                    font-weight: bold;
                  `}
                >
                  더 보기
                </span>
              </LinkSafe>
            </HeaderFlex>
            <PeopleList itemData={partner} isSwiper hash="partner" />
          </article>
        </Container>
      </SectionBecome>

      <SectionUnicaster className="by-sub-main-layout" id="unicaster">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                유니세프를 알리는 <br />
                크리에이터, 유니캐스터
              </Tit>
            </h2>
          </SectionHeader>

          <article>
            <PeopleList itemData={unicaster} hash="unicaster" isSwiper />
          </article>
        </Container>
      </SectionUnicaster>

      <SectionWork className="by-sub-main-layout" id="work">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                유니세프에서 <br />
                일한다는 건
              </Tit>
            </h2>
          </SectionHeader>

          <article>
            <PeopleList itemData={worker} hash="work" isSwiper />
          </article>
        </Container>
      </SectionWork>
    </LayoutWithTitle>
  );
};

export default People;
